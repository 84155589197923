import { CLEAR_GLOBAL_DATA, SET_GLOBAL_DATA } from "../types";

const initialState = {
  googleInitialized: false,
  adminPage: { name: "Home", slug: "home", child: "" },
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_GLOBAL_DATA:
      return initialState;
    case SET_GLOBAL_DATA:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

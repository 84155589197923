import React from "react";
import ReactDOM from "react-dom/client";
import clevertap from "clevertap-web-sdk";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import store from "store";
import "utils/initFirebase";
import AnalyticsContextProvider from "initGTM";
import ErrorBoundary from "elements/ErrorBoundary/ErrorBoundary";
clevertap.init("R5Z-Z8R-976Z", "sg1");

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <AnalyticsContextProvider>
    <Provider store={store}>
      <BrowserRouter>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </BrowserRouter>
    </Provider>
  </AnalyticsContextProvider>
  // </React.StrictMode>
);

serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

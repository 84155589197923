import { combineReducers } from 'redux'
import authorization from './auth'
import banner from './banner'
import cache from './cache'
import jobReady from './checkCertifJobReady'
import coupon from './coupon'
import course from './course'
import courseSpesial from './courseSpesial'
import dashboard from './dashboard'
import global from './global'
import globalCache from './globalCache'
import globalWatchCourse from './globalWatchCourse'
import page from './page'
import prakerja from './prakerja'
import search from './search'
import wishlist from './wishlist'

export default combineReducers({
  page,
  authorization,
  globalWatchCourse,
  dashboard,
  coupon,
  courseSpesial,
  banner,
  prakerja,
  wishlist,
  global,
  course,
  search,
  cache,
  globalCache,
  jobReady,
})

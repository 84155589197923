import { SET_CACHE_DATA, CLEAR_CACHE_DATA, FETCH_CACHE_DATA } from "../types";

const initialState = {
  googleInitialized: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_CACHE_DATA:
      return initialState;
    case SET_CACHE_DATA:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

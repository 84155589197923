import { SET_MESSAGE, CLEAR_MESSAGE, SET_COURSE_STATUS_CLASS, SET_DATA } from "../types";

const initialState = {};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_MESSAGE:
      return { globalWatchCourse: payload };

    case CLEAR_MESSAGE:
      return { globalWatchCourse: {} };

    case SET_DATA:
      return {
        ...state,
        ...action.payload
      }

    default:
      return state;
  }
}

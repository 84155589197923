import useKlikLuar from "elements/useKlikLuar"
import { FC, MutableRefObject, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setGlobalData } from "store/actions/global"
import "./PopupGeneral.scss"

export const PopupGeneral: FC<IPopupGeneral> = () => {
  const isiPopup = useSelector((state) => (state as any)?.global?.isiPopupArkademi || null)
  const ref = useRef<HTMLDivElement>(null)
  const { closePopup } = usePopup()
  useKlikLuar(ref, () => {
    closePopup()
  }, isiPopup !== null)
  if (isiPopup === null) return <></>
  return (
    <div className="kontainer-popup-general-apl-arkademi">
      <div className="kontainer-isi-popup" ref={ref}>
        {isiPopup}
      </div>
    </div>
  )
}

/**
 * cara penggunaan: 
 * 
 * const {openPopup, closePopup} = usePopup() 
 * 
 * <KomponenTrigger onClick={() => openPopup( \<IsiPopup /> )}/>
 * 
 */
const usePopup = () => {
  const dispatch = useDispatch()
  const openPopup = (isiPopup: JSX.Element) => {
    dispatch(setGlobalData("isiPopupArkademi", isiPopup))
  }
  const closePopup = () => {
    dispatch(setGlobalData("isiPopupArkademi", null))
  }

  return { openPopup, closePopup }
}

interface IPopupGeneral { }

export default usePopup
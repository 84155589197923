import { SET_GLOBAL_CACHE_DATA, CLEAR_GLOBAL_CACHE_DATA } from "../types";

const initialState = {
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case CLEAR_GLOBAL_CACHE_DATA:
            return initialState;
        case SET_GLOBAL_CACHE_DATA:
            return {
                ...state,
                ...payload
            }
        default:
            return state;
    }
}

import Analytics from 'analytics'
import googleTagManager from '@analytics/google-tag-manager'
import { createContext } from 'react'

const analytics = Analytics({
    app: 'arkademi web',
    plugins: [
        googleTagManager({
            containerId: 'GTM-WZWFRFL'
        })
    ]
})

export const AnalyticsContext = createContext(analytics)

const AnalyticContextProvider = ({ children }) => {
    return <AnalyticsContext.Provider value={analytics}>
        {children}
    </AnalyticsContext.Provider>
}

export default AnalyticContextProvider
import { useEffect } from "react";
import { MutableRefObject } from "react";

/**
 * saat klik diluar ref, maka akan menginvokasi fungsi
 * ref didapatkan dari const ref = useRef(null)
 * @param {MutableRefObject | undefined} ref 
 * @param {(terbuka: boolean) => void} fungsiTrigger 
 * @param {boolean} modalTerbuka 
 */

export default function useKlikLuar(ref, fungsiTrigger, modalTerbuka) {
  useEffect(() => {
    function handelKlikDiLuar(event) {
      if (ref && ref.current && !ref.current.contains(event.target) && modalTerbuka === true) {
        fungsiTrigger(false)
      }
    }
    document.addEventListener("mousedown", handelKlikDiLuar);
    console.log()
    return () => { document.removeEventListener("mousedown", handelKlikDiLuar) }
  }, [ref, modalTerbuka])
}
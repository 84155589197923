import {
  FETCH_COURSE_CATEGORY_ARRAY,
  FETCH_LOGO_MITRA,
  FETCH_NEW_COURSE,
  FETCH_BEST_SELLER,
  FETCH_COURSE_CERTIFIED,
  FETCH_CATEGORY_TOP,
  FETCH_COURSE_FINANCIAL,
  FETCH_ON_SALE,
  FETCH_CATEGORY_BOTTOM,
  FETCH_COURSE_CATEGORY,
  FETCH_RELATED_COURSE,
  FETCH_DETAIL_COURSE,
  FETCH_COURSE_BUSINESS,
  FETCH_HOME_TRENDING,
  FETCH_HOME_FLASSHALE,
  FETCH_HOME_BISNIS,
  FETCH_HOME_KARIR,
  FETCH_ICON_CATEGORY,
  FETCH_MENTOR,
  FETCH_COURSE_COINS,
  FETCH_HOME_JOB_READY,
} from "../types";

const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_LOGO_MITRA:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_CATEGORY_TOP:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_ON_SALE:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_NEW_COURSE:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_BEST_SELLER:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_COURSE_CERTIFIED:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_COURSE_BUSINESS:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_COURSE_FINANCIAL:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_CATEGORY_BOTTOM:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_DETAIL_COURSE:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_RELATED_COURSE:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_COURSE_CATEGORY:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_COURSE_CATEGORY_ARRAY:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_HOME_TRENDING:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_HOME_FLASSHALE:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_HOME_BISNIS:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_HOME_JOB_READY:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_HOME_KARIR:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_ICON_CATEGORY:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_MENTOR:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_COURSE_COINS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyCU-QEvKkYocRBYZfMDHMWJIzkpcMYyPDo",
    authDomain: "arkademi-flutter-v201.firebaseapp.com",
    databaseURL: "https://arkademi-flutter-v201-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "arkademi-flutter-v201",
    storageBucket: "arkademi-flutter-v201.appspot.com",
    messagingSenderId: "265001851813",
    appId: "1:265001851813:web:431d1d4c0bd515b4eea64c",
    measurementId: "G-B9PJTC3T3L"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app
export const db = getFirestore(app);

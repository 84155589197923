import { useEffect } from 'react'
import ReactGA from 'react-ga'
import { useLocation } from 'react-router'

ReactGA.initialize('UA-108193638-1')

const GATracker = (props) => {
  const trackPageView = (page) => {
    ReactGA.set({ page, ...props.options })
    ReactGA.pageview(page)
  }
  const location = useLocation()
  useEffect(() => {
    const page = location
    trackPageView(page)
  }, [location])

  return <props.WrappedComponent {...props} />
}

export default GATracker

import 'assets/scss/style_custome.scss'
import { PopupGeneral } from 'elements/PopupGeneral/PopupGeneral'
import 'primeicons/primeicons.css' //icons
import 'primereact/resources/primereact.min.css' //core css
import 'primereact/resources/themes/lara-light-indigo/theme.css' //theme
import { lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router'
import GATracker from 'utils/GATracker'
import './App.scss'
import './assets/flickity.scss'

const ResetPasswordPage = lazy(() => import('pages/ResetPasswordPage'))
const CreateNewPasswordPage = lazy(() => import('pages/CreateNewPasswordPage'))
const LoginPage = lazy(() => import('pages/LoginPage'))
const AdminPage = lazy(() => import('pages/AdminPage'))

function App() {
  return (
    <div className='App'>
      <PopupGeneral />
      <Suspense fallback={<div></div>}>
        <Routes>
          <Route
            path='/'
            element={<GATracker WrappedComponent={LoginPage} />}
          />
          <Route
            path='/reset-password'
            element={<GATracker WrappedComponent={ResetPasswordPage} />}
          />
          <Route
            path='/ganti-password'
            element={<GATracker WrappedComponent={CreateNewPasswordPage} />}
          />
          <Route
            path='/admin'
            element={<GATracker WrappedComponent={AdminPage} />}
          />
          <Route
            path='/admin/:slug'
            element={<GATracker WrappedComponent={AdminPage} />}
          />
        </Routes>
      </Suspense>
    </div>
  )
}

export default App

import { IconArrowCycle, IconErrorDesktop, IconErrorMobile } from "assets";
import useDimensiLayar from "utils/useDimensiLayar";
import "./ErrorBoundary.scss";

const ErrorBoundaryPage = () => {
  const [lebarLayar] = useDimensiLayar();

  function refreshPage() {
    window.location.reload(false);
  }

  return (
    <>
      <div className="error-boundary-page-wrapper">
        <div className="body-error-boundary d-flex">
          {lebarLayar > 1000 ? (
            <img
              src={IconErrorDesktop}
              alt=""
              className="icon-error-boundary"
            />
          ) : (
            <img src={IconErrorMobile} alt="" className="icon-error-boundary" />
          )}
          <h4 className="title">Oops! Terjadi kesalahan...</h4>
          <p className="description">
            Tunggu beberapa saat lagi ya, akan segera kami perbaiki. Coba lagi
            atau kembali lagi nanti.
          </p>
          <button className="btn-try-again d-flex" onClick={refreshPage}>
            <img src={IconArrowCycle} alt="" className="icon-try-again" />
            Coba Lagi
          </button>
        </div>
      </div>
    </>
  );
};

export default ErrorBoundaryPage;

import {
  FETCH_PRAKERJA_MARKETPLACE,
  FETCH_PRAKERJA_PUPULAR,
  FETCH_CHECK_CERTIFICATE,
} from "store/types";

const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PRAKERJA_MARKETPLACE:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_PRAKERJA_PUPULAR:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_CHECK_CERTIFICATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

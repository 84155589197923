import axios from "axios";
import { doc, setDoc, Timestamp } from "firebase/firestore";
import React from "react";
import { db } from "utils/initFirebase";
import ErrorBoundaryPage from "./ErrorBoundaryPage";

const api_firebase_url = process.env.REACT_APP_API_FIREBASE;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    if (environment === "p") {
      this.setState({ hasError: true });
      console.log({ error: error });
      const sendError = async () => {
        try {
          const data = (await axios.get(`${api_firebase_url}/v2/timestamp`))
            .data;
          const waktuSekon = (data?._seconds || 0) * 1000;
          const timestamp = Timestamp.fromMillis(waktuSekon);
          const dataError = {
            environment: environment,
            date: timestamp,
            message: error.message || "",
            stack: error.stack || "",
            location: window.location.href,
            info: JSON.stringify(info),
          };
          await setDoc(
            doc(db, "errorWebsite", timestamp.seconds.toString()),
            dataError
          );
        } catch (e) {
          console.error(e);
        }
      };
      sendError();
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <ErrorBoundaryPage />
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

import { useState, useEffect } from "react";

//return [lebarLayar, tinggiLayar]
//contoh const [lebar, tinggi] = useDimensiLayar()

export default function useDimensiLayar() {
  const [dimensiLayar, setDimensiLayar] = useState(dapatkanDimensiLayar());

  useEffect(() => {
    function handelResize() {
      setDimensiLayar(dapatkanDimensiLayar())
    }
    window.addEventListener('resize', handelResize)
    return () => window.removeEventListener('resize', handelResize)
  }, [])

  return dimensiLayar
}

function dapatkanDimensiLayar() {
  const { innerWidth: lebarLayar, innerHeight: tinggiLayar } = window;
  return [
    lebarLayar,
    tinggiLayar
  ]
}
import {
  FETCH_LIST_SEARCH,
  FETCH_SEARCH,
  SET_SEARCH_TERM,
  SET_HISTORY_SEARCH,
  DELETE_HISTORY_SEARCH,
} from "store/types";

const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_SEARCH:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_LIST_SEARCH:
      return {
        ...state,
        ...action.payload,
      };
    case SET_SEARCH_TERM:
      return {
        ...state,
        ...action.payload,
      };
    case SET_HISTORY_SEARCH:
      return {
        ...state,
        ...action.payload,
      };
    case DELETE_HISTORY_SEARCH:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
